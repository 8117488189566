import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import emailSvg from "./img/icons/Vectoremail.svg";
import lockSvg from "./img/icons/Vectorlock.svg";
import "./signInPage.scss";

function SignInPage() {
  const [value, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [emailError, setEmailError] = useState("Логін не може бути пустим");
  const [passwordError, setPasswordError] = useState(
    "Пароль не може бути порожнім"
  );
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (emailError || passwordError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [emailError, passwordError]);

  const emailHandler = (e) => {
    setEmail(e.target.value); //e.target.value
    const re = /^[\S\d]+@(net\.ua|org\.ua|gmail\.com)$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError("Цей логін не коректний");
    } else {
      setEmailError("");
    }
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value); //e.target.value
    if (e.target.value.length < 2 || e.target.value.length > 15) {
      setPasswordError("Пароль повинен бути довше 2 і менше 15 символів");
      if (!e.target.value) {
        setPasswordError("Пароль не може бути порожнім");
      }
    } else {
      setPasswordError("");
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "email":
        setEmailDirty(true);
        break;
      case "password":
        setPasswordDirty(true);
        break;
    }
  };

  function submit(e) {
    e.preventDefault();

    axios
      .post(
        `https://api.betrade.io/api/login/email?`,
        { value, password },
        {
          params: {
            value,
            password,
          }          
        }
      )
      .then(function (response) {
        console.log(response);
        console.log(response.data.data);
        localStorage.setItem("token", `Bearer ${response.data.data}`);
      })
      .catch(function (response) {
        console.log(response);
        console.log("error");
      });
  }

  let btn = (
    <button
      onClick={(e) => submit(e)}
      // disabled={!formValid} comment for hard code
      className='button_form family_inter'
      type='submit'
    >
      <Link style={{ textDecoration: "none", color: "white" }} to='/home'>
        <p
          style={{
            display: "flex",
            width: "106%",
            height: "105%",
            alignItems: "center",
            justifyContent: "center",
            margin: "-3%",
            borderRadius: "25px",
          }}
        >
          Sign In
        </p>
      </Link>
    </button>
  );

  return (
    <div className='wrapper_form '>
      <form className='container_form'>
        <p className='welcome_text family_inter'>Welcome!</p>
        <h1 className='title_text family_inter'>Sign in, please </h1>

        <div className='group_field'>
          <p className='user family_inter'>E-Mail</p>
          <img className='icons emailsvg' src={emailSvg} alt='email-icon' />
          <input
            onChange={(e) => emailHandler(e)}
            value={value}
            onBlur={(e) => blurHandler(e)}
            className='field email-border input_forms family_inter'
            name='email'
            type='text'
            placeholder='e.g.: elonmusk@mars.com'
          />
          {emailDirty && emailError && (
            <div
              style={{
                color: "red",
                fontSize: "11px",
                fontWeight: "bold",
                position: "absolute",
                top: "46vh",
                zIndex: "10",
              }}
            >
              {emailError}
            </div>
          )}
        </div>
        <div className='group_field'>
          <p className='password family_inter'>Password</p>
          <img className='icons locksvg' src={lockSvg} alt='email-icon' />

          <input
            onChange={(e) => passwordHandler(e)}
            value={password}
            onBlur={(e) => blurHandler(e)}
            className='field input_forms'
            name='password'
            type='password'
            placeholder='e.g.: X Æ A-12'
          />
          {passwordDirty && passwordError && (
            <div
              style={{
                color: "red",
                fontSize: "11px",
                fontWeight: "bold",
                position: "absolute",
                top: "60vh",
                zIndex: "10",
              }}
            >
              {passwordError}
            </div>
          )}
        </div>

        {btn}

        <div className='buttom_text'>
          <p className='first_text family_inter'>Already a member?</p>
          <Link to='/sign-up' className='second_text family_inter'>
            Sign up →
          </Link>
        </div>
      </form>
    </div>
  );
}

export default SignInPage;

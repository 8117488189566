import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { FormControl, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function CreateEventPage() {
  const [name, setName] = useState("Barselona vs Real Madrid");
  const [description, setDescription] = useState(
    "Strong opponents. Such events do not happen every day. The event will take place in england at the olympic stadium."
  );
  const [startTime, setStartTime] = useState("1649692800000");
  const [endTime, setEndTime] = useState("1650470400000");
  const [tradeCurrency, setTradeCurrency] = useState("USDT");
  const [playerOne, setPlayerOne] = useState("Barselona");
  const [playerTwo, setPlayerTwo] = useState("Real Madrid");
  const [playerOneDescription, setPlayerOneDescription] = useState(
    "Barcelona is a football club from Barcelona. Founded on November 29, 1899. One of the most famous and strongest clubs in Spain and the world. For over a century of history, this club has become one of the symbols of Catalonia."
  ); //на беці треба зробити щоб тут можна було писати більше ніж 254 символи
  const [playerTwoDescription, setPlayerTwoDescription] = useState(
    "Real Madrid, in full Real Madrid Club de Fútbol, byname Los Blancos (Spanish: “the White”), Spanish professional football (soccer) club based in Madrid. Playing in all-white uniforms, which led to its nickname “Los Blancos”."
  );

  const handlerName = (event) => {
    setName(event.target.value);
  };
  const handlerDescription = (event) => {
    setDescription(event.target.value);
  };
  const handlerStartTime = (event) => {
    setStartTime(event.target.value);
  };
  const handlerEndTime = (event) => {
    setEndTime(event.target.value);
  };
  const handlerTradeCurrency = (event) => {
    setTradeCurrency(event.target.value);
  };
  const handlerPlayerOne = (event) => {
    setPlayerOne(event.target.value);
  };
  const handlerPlayerTwo = (event) => {
    setPlayerTwo(event.target.value);
  };
  const handlerPlayerOneDescription = (event) => {
    setPlayerOneDescription(event.target.value);
  };
  const handlerPlayerTwoDescription = (event) => {
    setPlayerTwoDescription(event.target.value);
  };

  function submit(e) {
    e.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append("name", name);
    bodyFormData.append("description", description);
    bodyFormData.append("startTime", startTime);
    bodyFormData.append("endTime", endTime);
    bodyFormData.append("tradeCurrency", tradeCurrency);
    bodyFormData.append("tradeEventSideList[0].name", playerOne);
    bodyFormData.append("tradeEventSideList[1].name", playerTwo);
    bodyFormData.append(
      "tradeEventSideList[0].description",
      playerOneDescription
    );
    bodyFormData.append(
      "tradeEventSideList[1].description",
      playerTwoDescription
    );

    axios({
      method: "post",
      url: "https://api.betrade.io/api/trade-event",
      data: bodyFormData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      }
    })
      .then(function (response) {
        console.log(response);
        console.log(response.data);
      })
      .catch(function (response) {
        console.log(response);
        console.log("error");
      });
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControl
            onSubmit={(e) => submit(e)}
            component='form'
            sx={{
              ".MuiTextField-root": { width: "65.4ch" },
              mx: "auto",
              width: 700,
              p: 1,
              m: 1,
            }}
          >
            <Typography
              sx={{ mb: 1, ml: 2 }}
              fontWeight='500'
              variant='h5'
              component='h5'
            >
              <Button sx={{ mr: 1 }} variant='outlined'>
                <Link
                  style={{ textDecoration: "none", color: "#035397" }}
                  to='/home'
                >
                  Home
                </Link>
              </Button>
              <Button sx={{ mr: 1 }} variant='contained'>
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to='/event/get'
                >
                  View other events
                </Link>
              </Button>
              Creating event
            </Typography>
            <div>
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Event Name'
                maxRows={1}
                value={name}
                onChange={handlerName}
              />
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-static'
                label='Description Event'
                multiline
                rows={2}
                value={description}
                onChange={handlerDescription}
              />
            </div>
            <div>
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Start date'
                maxRows={1}
                value={startTime}
                onChange={handlerStartTime}
              />
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='End date'
                maxRows={1}
                value={endTime}
                onChange={handlerEndTime}
              />

              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Trade currency'
                maxRows={1}
                value={tradeCurrency}
                onChange={handlerTradeCurrency}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Side one name'
                maxRows={1}
                value={playerOne}
                onChange={handlerPlayerOne}
              />
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Description side one '
                multiline
                rows={2}
                value={playerOneDescription}
                onChange={handlerPlayerOneDescription}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Side second name'
                maxRows={1}
                value={playerTwo}
                onChange={handlerPlayerTwo}
              />
              <TextField
                sx={{ m: 0.5 }}
                id='outlined-multiline-flexible'
                label='Description side two'
                multiline
                rows={2}
                value={playerTwoDescription}
                onChange={handlerPlayerTwoDescription}
              />
            </div>

            <Button type='submit' variant='contained' sx={{ m: 0.5 }}>
              Create
            </Button>
          </FormControl>
        </div>
      </div>
    </>
  );
}

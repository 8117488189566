import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function Glasses() {
  const [askPrices, setAskPrices] = useState("");
  const [askVolumes, setAskVolumes] = useState("");

  const { id } = useParams();

  useEffect(() => {
    let interval = setInterval(() => {
      axios
        .get(
          `https://api.betrade.io/api/trade-engine/market-data?symbolId=${id}`
        )
        .then(function (response) {
          console.log("ok-getGlesses");
          setAskPrices(response.data.askPrices);
          setAskVolumes(response.data.askVolumes);
        })
        .catch(function (response) {
          console.log("error-getGlesses");
          console.log(response);
        });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  let sumArr = [];
  for (
    let i = 0, j = 0;
    i < askPrices.length, j < askVolumes.length;
    i++, j++
  ) {
    let res = askPrices[i] * askVolumes[j];
    sumArr.push(res / Math.pow(10, 2));
  }
  console.log(sumArr);

  const generateColumns = (arr1, arr2, arr3) => {
    const resArr = [];
    for (let i = 0; i < arr1.length; i++) {
      resArr.push({ askPrice: arr1[i], askVolume: arr2[i], sumArr: arr3[i] });
    }
    return resArr;
  };
  const resArr = generateColumns(askPrices, askVolumes, sumArr);

  const renderColumns = resArr.map(function (values, i) {
    return (
      <div key={i}>
        {Object.values(values).map((value, i) => (
          <td
            style={{
              width: "7.6vw",
              textAlign: "center",
            }}
            key={i}
          >
            {(value / Math.pow(10, 2)).toFixed(2)}
          </td>
        ))}
      </div>
    );
  });

  return (
    <>
      <div
        style={{
          color: "red",
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          width: "23vw",
        }}
      >
        {renderColumns}
      </div>
    </>
  );
}

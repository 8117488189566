import React, { useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import GlassAsk from "./GlassAsk";
import GlassBid from "./GlassBid";

export default function CreateOrder(props) {
  //ask_bid_order
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");

  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };
  const handleChangeSize = (event) => {
    setSize(event.target.value);
  };

  //ask_bid_glasses
  const [priceBid, setPriceBid] = useState("");
  const [sizeBid, setSizeBid] = useState("");

  const handleChangePriceBid = (event) => {
    setPriceBid(event.target.value);
  };
  const handleChangeSizeBid = (event) => {
    setSizeBid(event.target.value);
  };

  function ask(e) {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://api.betrade.io/api/trade-engine/order",
      data: JSON.stringify({
        symbolId: props.idSymbol,
        price: price,
        size: size,
        orderAction: "ASK",
        orderType: "GTC",
      }),
      headers: { "content-type": "application/json" },
    })
      .then(function (response) {
        console.log(response.data);
        console.log("ok-order");
      })
      .catch(function (response) {
        console.log(response);
        console.log("error-order");
      });
  }

  function bid(e) {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://api.betrade.io/api/trade-engine/order",
      data: JSON.stringify({
        symbolId: props.idSymbol,
        price: priceBid,
        size: sizeBid,
        orderAction: "BID",
        orderType: "GTC",
      }),
      headers: { "content-type": "application/json" },
    })
      .then(function (response) {
        console.log(response.data);
        console.log("ok-order");
      })
      .catch(function (response) {
        console.log(response);
        console.log("error-order");
      });
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  width: "25vw",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      height: "5vh",
                      flexDirection: "row",
                      fontSize: "12px",
                      width: "23vw",
                    }}
                  >
                    <td style={{ width: "7.6vw", textAlign: "center" }}>
                      Price(USDT)
                    </td>
                    <td style={{ width: "7.6vw", textAlign: "center" }}>
                      Size(BTC)
                    </td>
                    <td style={{ width: "7.6vw", textAlign: "center" }}>
                      Total
                    </td>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "end",
                      height: "50vh",
                      justifyContent: "center",
                    }}
                  >
                    <GlassAsk />
                  </Box>
                </Box>
              </TableCell>

              <TableCell
                style={{ height: "50vh", borderLeft: "1px solid #DDDDDD" }}
                colSpan='2'
              >
                graf
              </TableCell>
            </TableRow>

            <TableRow style={{ height: "40vh", borderBottom: "none" }}>
              <TableCell
                style={{
                  width: "25vw",
                  display: "flex",
                  justifyContent: "start",
                  height: "40vh",
                  alignItems: "start",
                  borderBottom: "none",
                }}
              >
                <Box>
                  <GlassBid />
                </Box>
              </TableCell>

              {/* bid_order */}
              <TableCell style={{ borderLeft: "1px solid #DDDDDD" }}>
                <Box
                  component='form'
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    style={{ width: "30vw" }}
                    label='price $'
                    value={priceBid}
                    onChange={handleChangePriceBid}
                  />
                  <br />
                  <TextField
                    style={{ width: "30vw" }}
                    label='size ₿'
                    value={sizeBid}
                    onChange={handleChangeSizeBid}
                  />
                  <br />
                  <Button
                    onClick={(e) => bid(e)}
                    style={{ width: "30vw" }}
                    variant='contained'
                    size='large'
                  >
                    buy
                  </Button>
                </Box>
              </TableCell>

              {/* ask_order */}
              <TableCell>
                <Box
                  component='form'
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    style={{ width: "30vw" }}
                    label='price $'
                    value={price}
                    onChange={handleChangePrice}
                  />
                  <br />
                  <TextField
                    style={{ width: "30vw" }}
                    label='size ₿'
                    value={size}
                    onChange={handleChangeSize}
                  />
                  <br />
                  <Button
                    onClick={(e) => ask(e)}
                    style={{ width: "30vw" }}
                    variant='contained'
                    size='large'
                  >
                    sell
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import nameSvg from "./img/icons/Vectoremail.svg";
import emailSvg from "./img/icons/Vectoremail.svg";
import lockSvg from "./img/icons/Vectorlock.svg";
import "./signUpPage.scss";

function SignUpPage() {
  const [name, setName] = useState("");
  const [value, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [nameDirty, setNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);

  const [nameError, setNameError] = useState("Введіть Ваше ім'я");
  const [emailError, setEmailError] = useState("Введіть Ваш е-mail");
  const [passwordError, setPasswordError] = useState("Введіть Ваш пароль");
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (nameError || emailError || passwordError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [nameError, emailError, passwordError]);

  const nameHandler = (e) => {
    setName(e.target.value);
    const re = /^[a-z0-9_-]{2,16}$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setNameError("Це ім'я не коректне");
    } else {
      setNameError("");
    }
  };
  const emailHandler = (e) => {
    setEmail(e.target.value);
    const re = /^[\S\d]+@(net\.ua|org\.ua|gmail\.com)$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError("Цей логін не коректний");
    } else {
      setEmailError("");
    }
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length < 2 || e.target.value.length > 15) {
      setPasswordError("Пароль повинен бути довше 2 і менше 15 символів");
      if (!e.target.value) {
        setPasswordError("Пароль не може бути порожнім");
      }
    } else {
      setPasswordError("");
    }
  };
  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
      case "email":
        setEmailDirty(true);
        break;
      case "password":
        setPasswordDirty(true);
        break;
    }
  };

  function submit(e) {
    e.preventDefault();

    axios
      .post(
        `https://api.betrade.io/api/user/email-exists?`,
        { value },
        {
          params: {
            value,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        console.log(response.data);
        if (response.data.data === true) {
          console.log("користувач вже зарейстрований");
          setTitle(`${name}, ви вже рейструвались`);
        } else if (response.data.data === false) {
          console.log("користувач не зарейстрований");

          let bodyFormData = new FormData();
          bodyFormData.append("userName", name);
          bodyFormData.append("email", value);
          bodyFormData.append("password", password);

          axios({
            method: "post",
            url: "https://api.betrade.io/api/registration",
            data: bodyFormData,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "multipart/form-data"
            },
          })
            .then(function (response) {
              console.log(response);
              console.log(response.data.data);
              localStorage.setItem("token", `Bearer ${response.data.data}`);
            })
            .catch(function (response) {
              console.log(response);
              console.log("error");
            });
        }
      })
      .catch(function (response) {
        console.log(response);
        console.log("error");
      });
  }

  const [title, setTitle] = useState("Join The BeTrade");

  return (
    <div className='wrapper_form'>
      <form onSubmit={(e) => submit(e)} className='container_form'>
        <p className='welcome_text family_inter'>Welcome!</p>
        <h1 className='title_text family_inter'>{title}</h1>

        <div className='group_field'>
          <p className='user family_inter'>Username</p>
          <img className='icons namesvg' src={nameSvg} alt='email-icon' />
          <input
            onChange={(e) => nameHandler(e)}
            id='name'
            value={name}
            onBlur={(e) => blurHandler(e)}
            className='field email-border input_forms family_inter'
            name='name'
            type='text'
            placeholder='e.g.: name'
          />
          {nameDirty && nameError && (
            <div
              style={{
                color: "red",
                fontSize: "11px",
                fontWeight: "bold",
                position: "absolute",
                top: "38.5vh",
                zIndex: "10",
              }}
            >
              {nameError}
            </div>
          )}
        </div>

        <div className='group_field'>
          <p className='user family_inter'>E-Mail</p>
          <img className='icons emailsvgReg' src={emailSvg} alt='email-icon' />
          <input
            onChange={(e) => emailHandler(e)}
            id='email'
            value={value}
            onBlur={(e) => blurHandler(e)}
            className='field email-border input_forms family_inter'
            name='email'
            type='text'
            placeholder='e.g.: elonmusk@mars.com'
          />
          {emailDirty && emailError && (
            <div
              style={{
                color: "red",
                fontSize: "11px",
                fontWeight: "bold",
                position: "absolute",
                top: "52vh",
                zIndex: "10",
              }}
            >
              {emailError}
            </div>
          )}
        </div>

        <div className='group_field'>
          <p className='password family_inter'>Password</p>
          <img className='icons locksvgReg' src={lockSvg} alt='email-icon' />

          <input
            onChange={(e) => passwordHandler(e)}
            id='password'
            value={password}
            onBlur={(e) => blurHandler(e)}
            className='field input_forms'
            name='password'
            type='password'
            placeholder='e.g.: X Æ A-12'
          />
          {passwordDirty && passwordError && (
            <div
              style={{
                color: "red",
                fontSize: "11px",
                fontWeight: "bold",
                position: "absolute",
                top: "65.5vh",
                zIndex: "10",
              }}
            >
              {passwordError}
            </div>
          )}
        </div>

        <button
          disabled={!formValid}
          className='button_form family_inter'
          type='submit'
        >
          Sign Up
        </button>

        <div className='buttom_text'>
          <p className='first_text family_inter'>Already a member?</p>
          <Link to='/sign-in' className='second_text family_inter'>
            Sign in →
          </Link>
        </div>
      </form>
    </div>
  );
}

export default SignUpPage;

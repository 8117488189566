import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function PablicPage() {
  return (
    <>
      <Box style={{ display: "flex"}}>
        <Typography sx={{mr: 1, ml: 2, mt: 2 }} variant='h3' component='h3'>
          BeTrade
        </Typography>
        <Button sx={{ mt: 2, mr: 1, mb: 1 }} variant='contained'>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to='/sign-up'
          >
            Sign up
          </Link>
        </Button>
        <Button sx={{ mt: 2, mr: 1, mb: 1 }} variant='outlined'>
          <Link
            style={{ textDecoration: "none", color: "#035397" }}
            to='/sign-in'
          >
            Sign in
          </Link>
        </Button>
      </Box>
    </>
  );
}

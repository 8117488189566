import React from "react";
import "./App.scss";
import axios from "axios";
//fonts material ui
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

//components
import Routes from "./routes/routes";

function App() {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token;

    return config;
  });

  return <Routes />;
}

export default App;

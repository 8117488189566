import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";

//for get event card
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export default function GetEventPage() {
  const [events, setEvents] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const {id} = useParams();

  useEffect(() => {
    axios
      .get("https://api.betrade.io/api/trade-event")
      .then(function (response) {
        setEvents(response.data);
        console.log(response.data);
      })
      .catch(function (response) {
        console.log(response);
        console.log("error-getEvent");
      });
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  }));

  // let startTimeNormal = new Date(start).toLocaleString("en-US");
  // let endTimeNormal = new Date(end).toLocaleString("en-US");


  const mappedData = events.map((item) => (
    <Box sx={{ flexGrow: 4, display: "flex", flexWrap: "wrap" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {Array.from(Array(1)).map((_, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <Item sx={{ m: "2px", border: "1px solid #EEEEEE" }}>
              <CardContent>
                <Typography variant='h4' component='div'>
                  {item.name}
                </Typography>
                <Typography sx={{ fontSize: 12 }} color='text.secondary'>
                  {new Date(item.startTime).toLocaleString("en-US")} - {new Date(item.endTime).toLocaleString("en-US")}
                </Typography>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  {item.description}
                </Typography>
                <Typography sx={{ mr: 1 }} variant='body3'>
                  {item.tradeCurrency}
                </Typography>
                <br />
                <br />
                <Typography variant='body3'>
                  {item.tradeEventSideList[0].name}
                </Typography>
                <Typography sx={{ fontSize: 12 }} color='text.secondary'>
                  {item.tradeEventSideList[0].description}
                </Typography>
                <br />
                <Typography variant='body3'>
                  {item.tradeEventSideList[1].name}
                </Typography>
                <Typography sx={{ fontSize: 12 }} color='text.secondary'>
                  {item.tradeEventSideList[1].description}
                </Typography>
              </CardContent>

              <CardActions>
                <Link
                  style={{ textDecoration: "none", color: "blue" }}
                  to={`${item.id}`}
                >
                  view
                </Link>
                {/* <Button size='small'>view</Button> */}
              </CardActions>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  ));

  return (
    <React.Fragment>
      <Typography
        sx={{ mb: 1, ml: 2 }}
        fontWeight='500'
        variant='h5'
        component='h5'
      >
        <Button sx={{ mt: 1, mr: 1, mb: 1 }} variant='outlined'>
          <Link style={{ textDecoration: "none", color: "#035397" }} to='/home'>
            Home
          </Link>
        </Button>
        <Button sx={{ mt: 1, mr: 1, mb: 1 }} variant='contained'>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to='/event/create'
          >
            Create event
          </Link>
        </Button>
        <br />
        All events
      </Typography>
      {mappedData}
    </React.Fragment>
  );
}

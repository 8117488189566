import React from 'react';


function NotFound() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', height: '50vh', alignItems: 'center'}}>
      <p style={{fontSize: '4vw'}}>404 Page not found.</p>
    </div>
  );
}

export default NotFound;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default function GetProfile() {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [balanceUSDT, setbalanceUSDT] = useState("");
  const [balanceBTC, setbalanceBTC] = useState("");
  const [joinData, setJoinData] = useState("");

  useEffect(() => {
    axios
      .get("https://api.betrade.io/api/user")
      .then((response) => {
        console.log("ok-profile");
        setId(response.data.id);
        setName(response.data.account.name);
        setEmail(response.data.email);
        setbalanceUSDT(response.data.account.balance.usdt);
        setbalanceBTC(response.data.account.balance.btc);
        setJoinData(response.data.joinDate);
      })
      .catch((response) => {
        console.log("error-profile");
        console.log(response);
      });
  }, []);

  let normalJoinData = new Date(joinData).toLocaleString("en-US");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body4,
    paddistng: theme.spacing(4),
    textAlign: "left",
    color: theme.palette.text.primary,
    padding: "20px",
    width: "40%",
  }));

  const ProfileData = (
    <Box
      style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
    >
      <Item
        sx={{
          m: "3px",
          border: "1px solid #EEEEEE",
          borderRadius: "7px",
        }}
      >
        <Typography
          sx={{ mr: "20px", fontWeight: "700" }}
          variant='h5'
          component='h5'
        >
          USDT: {balanceUSDT}
        </Typography>
        <Typography sx={{ fontWeight: "700" }} variant='h5' component='h5'>
          BTC: {balanceBTC}
        </Typography>
      </Item>

      <Item
        sx={{
          m: "3px",
          border: "1px solid #EEEEEE",
          borderRadius: "7px",
        }}
      >
        <Typography variant='h4' component='h4'>
          {name}
        </Typography>
        <Typography variant='h4' component='h4'>
          {email}
        </Typography>
      </Item>

      <Item
        sx={{
          m: "3px",
          border: "1px solid #EEEEEE",
          borderRadius: "10px",
        }}
      >
        <Typography variant='h4' component='h4'>
          User id:
        </Typography>
        <Typography variant='h5' component='h5'>
          {id}
        </Typography>
      </Item>

      <Item
        sx={{
          m: "3px",
          border: "1px solid #EEEEEE",
          borderRadius: "10px",
        }}
      >
        <Typography variant='h4' component='h4'>
          Join date:
        </Typography>
        <Typography variant='h5' component='h5'>
          {normalJoinData}
        </Typography>
      </Item>
    </Box>
  );

  return <>{ProfileData}</>;
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CreateOrder from "./CreateOrder";

export default function GetEventPage() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [tradeCurrency, setTradeCurrency] = useState("");
  const [sideOne, setSideOne] = useState("");
  const [sideTwo, setSideTwo] = useState("");
  const [sideOneDescription, setSideOneDescription] = useState("");
  const [sideTwoDescription, setSideTwoDescription] = useState("");

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://api.betrade.io/api/trade-event/${id}`)
      .then(function (response) {
        setName(response.data.name);
        setDescription(response.data.description);
        setStartTime(response.data.startTime);
        setEndTime(response.data.endTime);
        setTradeCurrency(response.data.tradeCurrency);
        setSideOne(response.data.tradeEventSideList[0].name);
        setSideTwo(response.data.tradeEventSideList[1].name);
        setSideOneDescription(response.data.tradeEventSideList[0].description);
        setSideTwoDescription(response.data.tradeEventSideList[1].description);

        console.log(response.data);
      })
      .catch(function (response) {
        console.log(response);
        console.log("error-getEvent");
      });
  }, []);

  let startTimeNormal = new Date(startTime).toLocaleString("en-US");
  let endTimeNormal = new Date(endTime).toLocaleString("en-US");

  const aboutEvent = (
    <div style={{ margin: "1em" }}>
      <h1>{name}</h1>
      <b>
        {startTimeNormal} - {endTimeNormal}
      </b>
      <br />
      <p>
        <b>Event description: </b>
        {description}
      </p>
      <br />
      <p>
        <b>Currency: </b>
        {tradeCurrency}
      </p>
      <br />
      <p>
        <strong>First side: </strong>
        {sideOne}
      </p>
      {sideOneDescription}
      <br />
      <br />
      <p>
        <strong>Second side: </strong>
        {sideTwo}
      </p>
      {sideTwoDescription}
      <hr />
    </div>
  );

  return (
    <>
      {aboutEvent}
      <CreateOrder idSymbol={id} />
    </>
  );
}

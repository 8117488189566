import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import Button from '@mui/material/Button';

function Logout() {
  function submit(e) {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://api.betrade.io/api/logout",
      //   data: token,
    })
      .then(function (response) {
        console.log(response);
        console.log(response.data);
        localStorage.removeItem("token");
      })
      .catch(function (response) {
        console.log(response);
        console.log("error");
      });
  }
  return (
    <div onClick={(e) => submit(e)} variant='contained'>
      <Link style={{ textDecoration: "none", color: "black" }} to='/sign-in'>
        logout
      </Link>
    </div>
  );
}

export default Logout;

import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

//PAGES
import SignUpPage from "../components/signPages/signUpPage/signUpPage";
import SignInPage from "../components/signPages/signInPage/signInPage";
import Home from "../pages/Home";
import NotFound from "./components/NotFound";
import CreateEventPage from "./../pages/EventPage/CreateEventPage";
import GetEventPage from "./../pages/EventPage/GetEventPage";
import View from "./../pages/EnginePage/View";
import PablicPage from '../pages/PablicPage/PablicPage';

function routes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/event/*'>
          <Route path='create' element={<CreateEventPage />} />
          <Route path='get' element={<GetEventPage />} />
          <Route path='get/:id' element={<View />} />
        </Route>
        <Route exact path='/home' element={<Home />} />
        <Route exact path='/logout' element={<Navigate to='/sign-in' />} />
        <Route exact path='/sign-in' element={<SignInPage />} />
        <Route exact path='/sign-up' element={<SignUpPage />} />
        <Route exact path='/' element={<PablicPage />} />
        <Route path='/not-found-404' element={<NotFound />} />
        <Route path='*' element={<Navigate to='/not-found-404' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default routes;
